<template id="">
 <div>
  <div class="admin">
    <h3>インテリジェントヘルスケア ストレスチェックApp</h3>
    <div v-show="!$store.state.common.sIsLoading && !$store.state.common.sDataLoading">
    <div class="tag-group">
      <ul>
        <li @click="isSelect('1')" v-bind:class="{'active': isActive == 1}">企業情報</li>
        <li @click="isSelect('2')" v-bind:class="{'active': isActive == 2}">従業員一覧</li>
        <li @click="isSelect('3')" v-bind:class="{'active': isActive == 3}">ストレスチェック受診歴</li>
      </ul>
    </div>
    <div id="data-area" v-cloak>
      <ci v-if="isActive == 1"></ci>
      <employee_list v-else-if="isActive == 2"></employee_list>
      <sch v-else-if="isActive == 3"></sch>
    </div>
    <button type="button" name="button" class="uk-button uk-button-secondary uk-width-1-2" @click="$router.back()">戻る</button>
  </div>
</div>
</div>
</template>

<script>
  import ci from "./company_info.vue"
  import employee_list from './employee_list.vue'
  import sch from './stress_check_history.vue'

  export default {
    components: {
      ci,
      employee_list,
      sch
    },
    data() {
      return {
        isActive: 1,
        status: null,

      }
    },
    mounted(){
      this.$store.state.common.sIsLoading = false;
      document.title = "企業詳細"
    },
    created(){
      this.$store.state.common.sIsLoading = true;
      this.$store.state.common.sDataLoading = true;

      this.status = this.$store.state.login_status_i
      this.checkLogined()
      if (this.status) {
        this.isActive = 1;
      }
      this.$store.state.common.sDataLoading = false;
    },
    methods: {
      isSelect(num) {
        this.isActive = num
      },

      checkLogined() {
        if (!this.status) {
          this.$router.push("/intelligent-login")
        }
      }
    }
  }
</script>

<style scoped>
  div {
    display: inline-block;
    width: 100%;
  }

  #data-area {
    max-height: 80vh;
    overflow-y: auto;
  }

  .tag-group {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    /* Fix for IE7 */
    *display: inline;
    /* Fix for IE7 */

  }

  ul {
    list-style: none;
    text-align: center;
  }

  .tab-group li {
    border: 1px solid #428bca;
    border-radius: 4px;
    position: relative;
    float: left;
    padding: 10px 15px 10px 15px;
  }

  .tab-group li:hover {
    background-color: #d4f0fd;
    cursor: pointer;
  }

  .active {
    background-color: #428bca;
    color: #fff;
  }

  .tab-group li+li {
    margin-left: -1px;
  }

  .tab-group>li:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  .tab-group>li:first-child {
    margin-left: 0;
  }

  .tab-group>li:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .tab-group>li:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  table{
    text-align:center;
    width:100%;
  }
  table tbody tr:hover{
    background: #d4f0fd;
    cursor: pointer;
  }

  .fixed01 {
    position: sticky;
    top: 0;
    color: #fff;
    background: #333;

    &:before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      width: 100%;
      height: 100%;
      border: 1px solid #eee;
    }
  }
  .overlays {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 200ms;
    visibility: hidden;
    opacity: 0;
  }

  .overlays .cancel {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    cursor: default;
  }

  .overlays:target {
    visibility: visible;
    opacity: 1;
  }

  .modals {
    margin: 0px auto;
    padding: 20px;
    background: #fff;
    border: 1px solid #666;
    width: 80%;
    height: 90%;
    border-radius: 6px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    position: relative;
  }

  .modals h2 {
    margin-top: 0;
  }

  .modals .close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    right: 20px;
    opacity: 0.8;
    transition: all 200ms;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: #777;
  }

  .modals .close:hover {
    opacity: 1;
  }

  .modals .cont {
    overflow-y: auto;
    max-height:80vh
  }

  .modals p {
    margin: 0 0 1em;
    text-align: center;
  }

  .modals p:last-child {
    margin: 0;
  }

  a.button:hover {
    box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
  }

  .overlays table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .overlays table tr {
    border-bottom: solid 1px #eee;
    cursor: pointer;
  }

  .overlays table tbody tr:hover {
    background-color: #d4f0fd;
  }

  .overlays table th,
  .overlays table td {
    text-align: center;
    width: 10%;
    padding: 15px 0;
  }

  [v-cloak] {
    display: none;
    cursor: wait;
  }
</style>
