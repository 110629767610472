<template id="">
  <div>
    <h3>インテリジェントヘルスケア ストレスチェックApp</h3>
    <div
      v-show="
        !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
      "
    >
      <div class="tag-group">
        <ul>
          <li @click="$router.replace('/intelligent-company_info/' + com_id)">
            企業情報
          </li>
          <li v-bind:class="{ active: true }">従業員一覧</li>
          <li
            @click="
              $router.replace('/intelligent-stress_check_history/' + com_id)
            "
          >
            ストレスチェック受診歴
          </li>
        </ul>
      </div>
      <table class="kigyo">
        <thead>
          <tr>
            <th class="fixed01" style="width: 6vw">従業員番号</th>
            <th class="fixed01">従業員名</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="li in employee_lists" :key="li.name">
            <tr>
              <td>{{ li.emp_code }}</td>
              <td>{{ li.name }}</td>
            </tr>
          </template>
        </tbody>
      </table>

      <div id="modal1" class="overlays" v-show="modal1_flg == true">
        <a
          class="cancel"
          @click="
            modal1_flg = !modal1_flg;
            pdf_url = null;
          "
        ></a>
        <div class="modals">
          <h2>従業員詳細</h2>
          <div class="cont">
            <table>
              <thead>
                <tr>
                  <th>実施管理ID</th>
                  <th>受診終了日</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ll in employee_detail_data" :key="ll.sc_id">
                  <td>{{ ll["sc_id"] }}</td>
                  <td>{{ ll["end_date"] }}</td>
                </tr>
              </tbody>
            </table>
            <p class="close">
              <a
                @click="
                  modal1_flg = !modal1_flg;
                  pdf_url = null;
                "
                >×</a
              >
            </p>
          </div>
        </div>
      </div>
      <div id="modal2" class="overlays" v-show="modal2_flg == true">
        <a
          class="cancel"
          @click="
            modal2_flg = !modal2_flg;
            modal1_flg = !modal1_flg;
            pdf_url = null;
          "
        ></a>
        <div class="modals">
          <h2>従業員詳細</h2>
          <div class="cont">
            <iframe
              :src="pdf_url"
              width="950"
              height="800"
              v-if="pdf_url != null"
            ></iframe>
            <p class="close">
              <a
                @click="
                  modal2_flg = !modal2_flg;
                  modal1_flg = !modal1_flg;
                  pdf_url = null;
                "
                >×</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      status: null,
      com_id: null,
      employee_lists: null,
      do_something: true,
      employee_detail_data: null,
      pdf_url: null,
      tmp: null,
      pdfs: [],
      modal1_flg: false,
      modal2_flg: false,
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "従業員一覧";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.status = this.$store.state.login_status_i;
    this.checkLogined();
    if (this.status) {
      this.com_id = this.$route.path.replace("/intelligent-employee_list/", "");
      this.get_employee_list();
    }
  },
  methods: {
    checkLogined() {
      if (!this.status) {
        this.$router.push("/intelligent-login");
      }
    },
    make_blob(base64_str) {
      let dataURI = "data:application/pdf;base64," + base64_str;
      // "iVBORw..."をバイナリに変換
      var byteString = atob(dataURI.split(",")[1]);

      // "image/png"
      var mimeType = dataURI.match(/(:)([a-z/]+)(;)/)[2];

      // バイナリからBlobを作成
      for (
        var i = 0, l = byteString.length, content = new Uint8Array(l);
        l > i;
        i++
      ) {
        content[i] = byteString.charCodeAt(i);
      }

      var blob = new Blob([content], {
        type: mimeType,
      });
      return blob;
      // window.open(window.URL.createObjectURL( blob )) ;
    },
    change_pdf(num) {
      this.pdf_url = this.pdfs[num];
    },
    show_result_pdf(sc_id) {
      if (!this.do_something) return;
      this.do_something = false;
      this.pdfs = [];
      this.$axios
        .post("/intelligent/get_personal_pdf", {
          sc_id: sc_id,
          emp_code: this.tmp,
        })
        .then((response) => {
          console.log("A");
          console.log(response);
          // let blob_res = new Blob([response.data], {type:"application/pdf"});
          // this.pdf_url = window.URL.createObjectURL(blob_res)
          if (response.data.flg) {
            let data_uri_pdf = response.data.pdf;
            this.pdf_url = this.make_blob(data_uri_pdf);
            this.pdf_url = window.URL.createObjectURL(this.pdf_url);
            this.do_something = true;
            this.modal2_flg = true;
            // window.open(window.URL.createObjectURL(this.pdf_url))
          } else {
            this.pdf_url = null;
            this.do_something = true;
          }
        })
        .catch((error) => {
          console.log("B");
          console.log(error);
          this.do_something = true;
        });
      this.do_something = true;
    },
    employee_detail(emp_code) {
      if (!this.do_something) return;
      this.do_something = false;
      this.tmp = emp_code;
      this.employee_detail_data = this.$route.path.replace(
        "/intelligent/employee_list/",
        ""
      );
      this.$axios
        .post("/intelligent/get_employee_detail", {
          com_id: this.com_id,
          emp_code: emp_code,
        })
        .then((response) => {
          if (response.data.flg) {
            this.employee_detail_data = response.data.implementation_datas;
            this.modal1_flg = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.do_something = true;
    },
    get_employee_list() {
      this.com_id = this.$route.path.replace("/intelligent-employee_list/", "");
      this.$axios
        .post("/intelligent/get_employee_list", {
          com_id: this.com_id,
        })
        .then((response) => {
          console.log(response);
          if (response.data.flg) {
            this.employee_lists = response.data.employee_lists;
          } else {
            console.log(
              "エラーが発生しました。\nお手数ですがもう一度やり直してください。"
            );
          }
          this.$store.state.common.sDataLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$store.state.common.sDataLoading = false;
        });
    },
  },
};
</script>

<style scoped>
table {
  text-align: center;
  width: 100%;
}
table tbody tr:hover {
  background: #d4f0fd;
  cursor: pointer;
}

.overlays {
  /* 要素を重ねた時の順番 */
  z-index: 1;

  /* 画面全体を覆う設定 */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  /* 画面の中央に要素を表示させる設定 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlays .cancel {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  cursor: default;
}

.overlays:target {
  visibility: visible;
  opacity: 1;
}

.modals {
  margin: 0px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #666;
  width: 80%;
  height: 90%;
  border-radius: 6px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modals h2 {
  margin-top: 0;
}

.modals .close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 20px;
  right: 20px;
  opacity: 0.8;
  transition: all 200ms;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #777;
}

.modals .close:hover {
  opacity: 1;
}

.modals .cont {
  overflow-y: auto;
  max-height: 80vh;
}

.modals p {
  margin: 0 0 1em;
  text-align: center;
}

.modals p:last-child {
  margin: 0;
}

a.button:hover {
  box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
}

.overlays table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.overlays table tr {
  border-bottom: solid 1px #eee;
  cursor: pointer;
}

.overlays table tbody tr:hover {
  background-color: #d4f0fd;
}

.overlays table th,
.overlays table td {
  text-align: center;
  width: 10%;
  padding: 15px 0;
}

.fixed01 {
  position: sticky;
  top: 0;
  color: #fff;
  background: #333;

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
  }
}
div {
  display: inline-block;
  width: 100%;
}

#data-area {
  max-height: 80vh;
  overflow-y: auto;
}

.tag-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  /* Fix for IE7 */
  *display: inline;
  /* Fix for IE7 */
}

ul {
  list-style: none;
  text-align: center;
}

.tab-group li {
  border: 1px solid #428bca;
  border-radius: 4px;
  position: relative;
  float: left;
  padding: 10px 15px 10px 15px;
}

.tab-group li:hover {
  background-color: #d4f0fd;
  cursor: pointer;
}

.active {
  background-color: #428bca;
  color: #fff;
}

.tab-group li + li {
  margin-left: -1px;
}

.tab-group > li:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.tab-group > li:first-child {
  margin-left: 0;
}

.tab-group > li:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-group > li:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
table {
  text-align: center;
  width: 100%;
}
table tbody tr:hover {
  background: #d4f0fd;
  cursor: pointer;
}
</style>
